/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  overflow: hidden; /* Prevents scrolling issues with shapes */
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items towards the top of the screen */
  height: 100vh;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  z-index: 10; /* Ensure content stays on top */
}

.background-shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allows clicks through shapes */
}

.shape {
  position: absolute;
  width: 60px; /* Slightly increased size */
  height: 60px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 5;
  transition: left 0.1s ease-in-out, top 0.1s ease-in-out; /* Smooth transition for movement */
  animation: float 10s ease-in-out infinite;
}

/* Float animation for a subtle floating effect */
@keyframes float {
  0%,
  100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(50px, -30px) scale(1.2);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px; /* Increase space at the top */
  margin-bottom: 50px; /* Reduce space below "Daniel Stone" and logo */
}

.name {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
}

.name h1 {
  font-size: 3rem;
  margin: 0;
  line-height: 1.2;
}

.avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}

.title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 25px;
  animation: pulse-once 1.5s ease-in-out;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 35px;
}

.button {
  padding: 15px 30px;
  font-size: 1.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  animation: slideInUp 1.5s ease-in-out;
}

.button:hover {
  background-color: #555;
  transform: scale(1.1);
}

.footer {
  margin-top: 35px;
  animation: fadeInUp 2s ease-in-out;
}

.buttons .button:last-child {
  margin-bottom: 35px;
}

.github-icon {
  width: 40px;
  height: 40px;
  filter: grayscale(100%);
  transition: filter 0.3s;
}

.github-icon:hover {
  filter: grayscale(0%);
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    margin-top: 60px; /* Adjust top margin for mobile */
    margin-bottom: 30px; /* Adjust bottom margin for mobile */
  }

  .name h1 {
    font-size: 2rem; /* Smaller font size for mobile */
  }

  .avatar {
    width: 80px;
    height: 80px; /* Smaller avatar for mobile */
    margin-top: 10px;
  }

  .title {
    font-size: 2.5rem; /* Smaller title size for mobile */
    margin-bottom: 20px;
  }

  .buttons {
    margin-top: 25px; /* Adjust button margin for mobile */
  }

  .button {
    font-size: 1.2rem;
    padding: 10px 20px; /* Smaller buttons for mobile */
  }

  .footer {
    margin-top: 25px;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse-once {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .name h1 {
    font-size: 2.5rem;
  }

  .title {
    font-size: 3rem;
  }

  .button {
    font-size: 1.2rem;
    padding: 10px 20px;
  }
}
